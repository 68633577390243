<template>
  <div class="alert-form">
    <div class="form-group form-group-sm question" style="">
      <label class="clicable" for="checkbox">
        <input type="checkbox" id="checkbox" v-model="confirmation.enabled" />
        {{ $t("require_confirmation") }}
      </label>
      <span class="pull-right clicable">
        <i
          v-if="confirmation.enabled"
          :class="
            collapsed ? 'fa fa-caret-square-o-down' : 'fa fa-caret-square-o-up'
          "
          @click.stop.prevent="collapsed = !collapsed"
        ></i>
      </span>
    </div>
    <div title="alert" v-if="confirmation.enabled && !collapsed">
      <div>
        <div class="form-group form-group-sm">
          <label for="">{{ $t("title") }} </label>
          <input
            class="form-control"
            type="text"
            v-model="confirmation.title"
          />
        </div>
        <div class="form-group form-group-sm">
          <label for="">{{ $t("text") }} </label>
          <input class="form-control" type="text" v-model="confirmation.text" />
        </div>
        <div class="form-group form-group-sm">
          <label for="icon">{{ $tc("icon", 1) }}</label>
          <select class="form-control" v-model="confirmation.icon" id="icon">
            <option value="">{{ $t(`alert_icons.none`) }}</option>
            <option v-for="icon in icon_list" :key="icon" :value="icon">{{
              $t(`alert_icons.${icon}`)
            }}</option>
          </select>
        </div>
        <div class="form-group form-group-sm">
          <label>{{ $tc("button", 2) }}</label>
          <div>
            <div class="input-group">
              <div class="input-group-addon">
                <i class="fa fa-close"></i>
              </div>
              <input
                class="form-control"
                :placeholder="$t('cancel')"
                type="text"
                v-model="confirmation.buttons.cancel.text"
              />
              <div
                class="input-group-addon btn"
                @click.stop.prevent="
                  confirmation.buttons.cancel.visible = !confirmation.buttons
                    .cancel.visible
                "
              >
                <i
                  :class="
                    confirmation.buttons.cancel.visible
                      ? 'fa fa-eye'
                      : 'fa fa-eye-slash'
                  "
                ></i>
              </div>
            </div>
            <div class="input-group">
              <div class="input-group-addon">
                <i class="fa fa-check"></i>
              </div>
              <input
                class="form-control"
                :placeholder="$t('confirm')"
                type="text"
                v-model="confirmation.buttons.confirm.text"
              />
            </div>
          </div>
        </div>
      </div>
      <div class="text-center">
        <span class="btn btn-xs btn-default" @click.stop.prevent="onTest">{{
          $t("simulation")
        }}</span>
      </div>
    </div>
  </div>
</template>
<script>
import { isEqual } from "lodash";
export default {
  name: "AlertForm",
  props: {
    value: {
      type: Object,
      required: false,
      default: () => null
    }
  },
  data() {
    return {
      confirmation: {
        enabled: false,
        title: this.$t("are_you_sure"),
        text: this.$t("you_wont_be_able_to_revert_this"),
        icon: "warning",
        buttons: {
          cancel: {
            text: this.$t("cancel"),
            value: null,
            visible: true,
            className: "",
            closeModal: true
          },
          confirm: {
            text: this.$t("confirm"),
            value: true,
            visible: true,
            className: "",
            closeModal: true
          }
        }
      },
      icon_list: ["warning", "error", "success", "info"],
      collapsed: false
    };
  },
  computed: {
    isEnabled() {
      return this.confirmation.enabled;
    }
  },
  watch: {
    isEnabled(n) {
      if (n && this.collapsed) {
        this.collapsed = false;
      }
    },
    value: {
      handler(n) {
        if (n) {
          let current = JSON.parse(JSON.stringify(this.confirmation));
          let entry = {
            ...current,
            ...JSON.parse(JSON.stringify(this.value))
          };
          if (!isEqual(current, entry)) {
            this.$set(this, "confirmation", entry);
          }
        }
      },
      deep: true,
      immediate: true
    },
    confirmation: {
      handler(n) {
        this.$emit("input", n);
      },
      deep: true
    }
  },
  methods: {
    onTest() {
      this.$swal(this.confirmation).then((confirm) => {
        this.$toasted.show(
          confirm ? this.$t("confirmed") : this.$t("canceled"),
          {
            duration: 2000,
            position: "bottom-right",
            iconPack: "fontawesome"
          }
        );
      });
    }
  }
};
</script>

<style scoped>
.alert-form {
  padding: 0;
}
.question {
  margin: 10px 0;
}
.question input {
  vertical-align: top;
  margin-right: 6px;
}
.clicable:hover {
  opacity: 0.8;
  cursor: pointer;
}
label.checkbox {
  display: inline-block;
  margin: 0 30px 0 20px;
}
.input-group-addon > i {
  min-width: 16px;
}
</style>

<template>
  <div class="dashboard-inner">
    <div
      class="row"
      :class="'row-' + mode"
      v-for="(row, ixRow) in grid"
      v-bind:key="ixRow"
    >
      <div
        class="col-xs-12"
        v-for="(col, ixCol) in row"
        v-bind:key="ixCol"
        v-bind:class="col.column_class"
      >
        <div v-for="(panel, ixPanel) in col.panels" v-bind:key="ixPanel">
          <EquipmentDashboardPanel
            :equipment="equipment"
            :display="display"
            :name="panel"
            :screenId="screenId"
            @mounted="panelMounted"
          />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
/*
this component displays panels based on configuration
 gridLayout is a composed array: 
   rows [ columns [  [panels] ] ]
  
   - column width are always in % - but it is not required
   - panels within same column are stacked 
*/
import EquipmentDashboardPanel from "@/components/equipment-dashboard-panel.vue";
import { debounceTime } from "@/utils";

const panelBottomMargin = 10;
const dashboardHeaderHeight = 70;

export default {
  name: "EquipmentDashboardCustom",
  components: {
    EquipmentDashboardPanel
  },
  props: {
    equipment: {
      type: Object,
      required: false,
      default: () => ({})
    },
    display: {
      type: Object,
      required: true
    },
    name: {
      type: String,
      default: "equipment_dashboard"
    },
    screenId: {
      type: [String, Number],
      default: () => 0
    }
  },
  data() {
    return {
      busy: false
    };
  },
  computed: {
    mode() {
      return this.$route.path.startsWith("/dashboard/screen")
        ? "editor"
        : "viewer";
    },
    preview() {
      return this.$route.path.startsWith("/dashboard/screen/view");
    },
    expandedPanel() {
      return this.$store.getters["dashboard/expandedPanel"];
    },
    panels() {
      let lst = null;
      if (this.expandedPanel) {
        lst = [this.expandedPanel];
      } else {
        lst = this.display.panels
          .filter((panel) => {
            return this.can(panel);
          })
          .map((panel) => {
            return panel.name;
          });
      }
      return lst;
    },
    layout() {
      var self = this;
      var role = self.$store.getters["user/loggedUserRole"] || null;
      if (role && "layout" in role) {
        return role.layout;
      } else {
        if (self.display && "layout" in self.display) {
          if (this.mode == "editor") return self.display.layout;
          return JSON.parse(JSON.stringify(self.display.layout)).filter(
            (cols) =>
              cols.filter((col) => {
                col.panels = col.panels.filter((panel) => {
                  let p = self.display.panels.find((p) => p.name == panel);
                  if (p.view_permission?.length) {
                    return p.view_permission.some((id) =>
                      this.$store.getters["user/hasUserAccessTo"](id)
                    );
                  }
                  return true;
                });
                return col.panels.length;
              }).length
          );
        }
      }
      return null;
    },
    grid() {
      let self = this;
      let grid = [];
      if (this.busy) return grid;
      let layout = self.layout;
      if (layout) {
        layout.forEach(function(items) {
          grid.push(self.buildClasses(self.row(items)));
        });
      }
      return grid.filter((row) => row.length > 0);
    },
    largePanel() {
      return (
        this.$store.getters["dashboard/expandedPanel"] ||
        this.$store.getters["dashboard/fullscreenPanel"] ||
        ""
      );
    },
    sidebar() {
      return this.$store.getters["dashboard/sidebar"] || null;
    }
  },
  watch: {
    largePanel(panelName) {
      if (panelName) {
        let panel = this.connectorPanel(panelName);
        if (!panel) return;
        let style = JSON.parse(JSON.stringify(panel?.style || {}));
        delete style["min-height"];
        delete style["max-height"];
        this.$set(panel, "style", style);
      }
    },
    expandedPanel(n) {
      if (!this.display.render_version) return;
      if (!n && this.layout) {
        this.delayedResize();
      } else if (n) {
        window.scrollTo(0, 0);
      }
    },
    sidebar: {
      handler() {
        if (!this.display.render_version) return;
        if (!this.expandedPanel && this.layout) {
          this.delayedResize();
        }
      },
      deep: true
    }
  },
  methods: {
    panelMounted(panelName) {
      this.$nextTick(() => {
        setTimeout(() => {
          let el = document.getElementsByClassName(`__${panelName}__`);
          if (!el || !el.length) return;
          this.delayedResize();
        }, 1000);
      });
    },
    can(panel) {
      if (panel) {
        if (panel.rule) {
          return this.$can("view", panel.rule);
        }
        return true;
      }
      return false;
    },
    row(items) {
      let lst = JSON.parse(JSON.stringify(items));
      lst.forEach((item) => {
        item.panels = item.panels.filter((name) => {
          return this.panels.indexOf(name) >= 0;
        });
      });
      return lst;
    },
    buildClasses: function(row) {
      let max_col = 12;
      if (this.mode == "editor" && !this.preview) {
        if (this.sidebar && !this.sidebar.collapsed) {
          max_col = 10;
        }
      }
      let columns = [];
      for (var icol in row) {
        let hasPanel = false;
        for (var ipanel in row[icol].panels) {
          var panelName = row[icol].panels[ipanel];
          if (panelName) {
            hasPanel = true;
          } else {
            if (icol > 0) {
              row[icol - 1].width += row[icol].width;
            }
          }
        }
        if (hasPanel) {
          columns.push(row[icol]);
        }
      }
      var sum_informed = columns
        .map(function(i) {
          return i.width || 0;
        })
        .reduce((a, b) => a + b, 0);
      var sum_pending = 100 - sum_informed;
      var empty_ones = columns.filter(function(i) {
        return !("width" in i) || i.width <= 0 || i.width >= 100;
      }).length;
      var portion = empty_ones ? sum_pending / empty_ones : 0;
      var sum_calc = 0;

      columns
        .map(function(i) {
          i.width_calc = i.width || portion;
          sum_calc += i.width_calc;
          return i;
        })
        .map(function(i) {
          i.width_class_value =
            sum_calc > 0
              ? Math.round((i.width_calc * max_col) / sum_calc)
              : max_col;
          i.column_class =
            "col-sm-" +
            (i.width_class_value > 0 ? i.width_class_value : max_col);
          return i;
        });
      return columns;
    },
    refresh() {
      this.busy = true;
      this.$nextTick(() => {
        this.busy = false;
      });
    },
    editorPanel(panelName) {
      if (this.mode != "editor") return null;
      // check current one
      return (
        this.$store.getters["dashboard/draftPanel"]({
          screenId: this.screenId,
          panelName: panelName
        }) || null
      );
    },
    connectorPanel(panelName) {
      return (this?.display?.panels || []).find((i) => i.name == panelName);
    },
    panel(panelName) {
      return this.mode == "editor"
        ? this.editorPanel(panelName) || this.connectorPanel(panelName)
        : this.connectorPanel(panelName);
    },
    onResizeReferenceColumn(row) {
      if (!this.display.render_version || !this.layout) return;
      // let rowHeight = 0;
      // (this.layout[row][0].panels || []).forEach((panelName, i) => {
      //   var panel = this.panel(panelName);
      //   let el = document.getElementsByClassName(`__${panelName}__`);
      //   if (!panel || !el || !el.length) return;
      //   if (panel.template == "SynopticPanel") {
      //     rowHeight += el[0].scrollHeight || el[0].clientHeight;
      //     return;
      //   }
      //   el[0].style.height = "auto";
      //   let height = el[0].scrollHeight || el[0].clientHeight;
      //   height = this.setPanelHeight(panel, el[0], height);
      //   //if (i > 0) height += panelBottomMargin;
      //   rowHeight += height;
      // });
      // return rowHeight;
      let panelName = this.layout[row][0].panels[0];
      let el = document.getElementsByClassName(`__${panelName}__`);
      return el.length
        ? el[0].parentNode.parentNode.parentNode.clientHeight
        : 0;
    },
    onResizeColumn(row, col, rowHeight) {
      let el = null;
      let panel = null;
      let total = 1;
      let nAutos = 0;
      let dist = [];
      (this.layout[row][col].panels || []).forEach((panelName) => {
        panel = this.panel(panelName);
        el = document.getElementsByClassName(`__${panelName}__`);
        if (!panel || !el || !el.length) return;
        if ("heightProportion" in panel) {
          let perc = panel.heightProportion;
          if (perc > 0) {
            total -= panel.heightProportion;
          } else {
            perc = "?";
            nAutos += 1;
          }
          dist.push({ name: panel.name, perc: perc });
          if (el && el.length) {
            el[0].style.height = "0";
            el[0].style["min-height"] = "auto";
            el[0].style["max-height"] = "auto";
          }
        }
      });

      rowHeight -=
        (this.layout[row][col].panels || []).length * panelBottomMargin;
      dist.forEach((item, i) => {
        if (item.perc == "?") {
          item.perc = total / nAutos;
        }
        var height = Math.round(rowHeight * item.perc);
        //        if (i > 0) height -= panelBottomMargin;
        panel = this.panel(item.name);
        el = document.getElementsByClassName(`__${item.name}__`);
        if (el && el.length) {
          this.setPanelHeight(panel, el[0], height);
        }
      });
    },
    setPanelHeight(panel, el, height) {
      let style = el.style;
      var minHeight = ((panel?.style || {})["min-height"] || "").replace(
        /(px|auto)/gi,
        ""
      );
      var maxHeight = ((panel?.style || {})["max-height"] || "").replace(
        /(px|auto)/gi,
        ""
      );
      // style.minHeight = minHeight ? `${minHeight}px` : "auto";
      // style.maxHeight = maxHeight ? `${maxHeight}px` : "auto";
      if (minHeight) {
        height = height > minHeight ? height : minHeight;
        style.minHeight = `${minHeight}px`;
      } else {
        style.minHeight = 0;
      }
      if (maxHeight) {
        height = height < maxHeight ? height : maxHeight;
        style.maxHeight = `${maxHeight}px`;
      } else {
        //style.maxHeight = `${height}px`;
      }
      style.height = `${height}px`;
      return parseInt(height);
    },
    delayedResize: debounceTime(50, function() {
      this.$nextTick(() => {
        this.resize();
      });
    }),
    resize() {
      if (!this.display.render_version || !this.layout) return;
      for (var row in this.layout) {
        let rowHeight = this.onResizeReferenceColumn(row);
        if (!rowHeight) return;
        if (this.layout[row].length > 1) {
          for (var col = 1; col < this.layout[row].length; col++) {
            this.onResizeColumn(row, col, rowHeight);
          }
        }
      }
    }
  },
  mounted() {
    this.$root.$on("dashboard:refresh", this.refresh);
    if (this.display.render_version) {
      this.resize();
      this.$root.$on("panel:resized", this.delayedResize);
      window.addEventListener("resize", this.delayedResize, false);
    }
  },
  beforeDestroy() {
    (this.display.panels || []).forEach(({ name }) => {
      // console.log(this.display);
      if (name !== "synoptic" && this.$store.hasModule(name)) {
        this.$store.unregisterModule(name);
      }
    });
    this.$root.$off("dashboard:refresh", this.refresh);
    this.$root.$off("panel:resized", this.delayedResize);
    window.removeEventListener("resize", this.delayedResize, false);
  }
};
</script>

<style scoped>
@media (min-width: 768px) {
  .row-editor > .col-sm-10 {
    width: 81.333333%;
  }

  .row-editor > .col-sm-5 {
    width: 40.6666665%;
  }

  .row-editor > .col-sm-3 {
    width: 24.3%;
  }

  .row-editor > .col-sm-4 {
    width: 32.733333%;
  }
}
</style>

import Vue from "vue";
import VueResource from "vue-resource";
import Auth from "@/services/auth.js";

Vue.use(VueResource);

const baseurl = ""; //v1  set to "" after conversion
export default class DataCurrentValueService {
  /*
  make it compatible with previous version
  */
  dataCurrentValueAdapter(dataCurrentValue) {
    dataCurrentValue.identity_name =
      dataCurrentValue.identity_name || dataCurrentValue.name || "";
    return dataCurrentValue;
  }

  async fetchAll(query) {
    let self = this;
    return new Promise((resolve) => {
      let url = `${baseurl}data_current_value/?format=json`;
      let auth = new Auth();
      if (query) {
        for (var prop in query) {
          let vlr = encodeURIComponent(query[prop]);
          url += `&${prop}=${vlr}`;
        }
      }
      let request = Vue.http.get(url, auth.requestOptions());
      request.then(
        (response) => {
          var data = null;
          if (response && response.bodyText) {
            try {
              data = response.body || JSON.parse(response.bodyText) || null;
              data = (data || []).map(function(item) {
                return self.dataCurrentValueAdapter(item);
              });
              resolve(data);
              return;
            } catch (e) {
              //console.log("Could not parse the display");
            }
          }
          resolve(null);
        },
        (error) => {
          resolve(null);
        }
      );
    });
  }

  async save(payload) {
    let self = this;
    let auth = new Auth();
    return new Promise((resolve, reject) => {
      let url = `${baseurl}data_current_value/`; //v1
      let request = null;
      request = Vue.http.post(url, payload, auth.requestOptions());
      request.then(
        (response) => {
          var data = null;
          if (response && response.bodyText) {
            try {
              data = JSON.parse(response.bodyText);
              resolve(data);
              return;
            } catch (e) {
              //console.log("Could not parse the display");
            }
          }
          resolve(null);
        },
        (response) => {
          //console.error(response);
          reject(
            response.body.detail ||
              response.body.non_field_errors?.[0] ||
              response.statusText
          );
        }
      );
    });
  }
}

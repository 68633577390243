<template>
  <div>
    <div
      :class="inline ? 'inline-block' : editable ? 'inline-flex' : 'block'"
      :style="{
        'max-width': '100%'
      }"
    >
      <div class="form-inline pull-left" v-if="inline">
        <div class="form-group" style="margin-bottom: 0; margin-right: 1em;">
          <label
            class="checkbox-inline"
            style="margin-right: 1em;"
            v-if="parentProcessAreaId"
          >
            <input
              type="checkbox"
              id="inherits_parent_process_area"
              data-testid="inherits-process-area"
              v-model="inherits_parent_process_area"
              v-bind:disabled="process_area_editing || disabled"
            />
            {{ $t(`has_the_same_process_area_as_its.${label}`) }}
            <ToolTip
              :title="$t(`hints.inherits_parent_process_area.${label}`)"
            />
          </label>
          <label style="margin-right: 1em;" v-else>
            {{ $tc("process_area") }}
            <ToolTip :title="hint || $parent.$t('hints.process_area')" />
          </label>
          <div class="input-group" v-if="!process_area_editing">
            <div
              class="input-group-addon  hidden-xs"
              v-if="parentProcessAreaId"
            >
              {{ $tc("process_area") }}
            </div>
            <div class="form-control" v-if="lastAreaName">
              <i class="fa fa-refresh fa-spin"></i>
            </div>
            <select
              v-else
              class="form-control"
              data-testid="process-area"
              v-model="process_area_id"
              v-bind:disabled="
                !$can('manage', 'AreaDeProcessoAcesso') ||
                  inherits_parent_process_area ||
                  lastAreaName != '' ||
                  disabled
              "
              style="min-width: 140px"
              :title="(processArea && processArea.description) || ''"
            >
              <option
                v-for="(item, index) in processAreas"
                v-bind:key="index"
                v-bind:value="item.id"
                v-bind:title="item.description"
                >{{ item.name }}</option
              >
            </select>
          </div>
        </div>
      </div>
      <div
        class="form-group"
        style="margin-bottom: 0;flex: 0 0 calc(100% - 80px);"
        v-else
      >
        <label class="checkbox-inline" v-if="parentProcessAreaId">
          <input
            type="checkbox"
            id="inherits_parent_process_area"
            data-testid="inherits-process-area"
            v-model="inherits_parent_process_area"
            v-bind:disabled="process_area_editing || disabled"
          />
          {{ $t(`has_the_same_process_area_as_its.${label}`) }}
          <ToolTip :title="$t(`hints.inherits_parent_process_area.${label}`)" />
        </label>
        <label v-else>
          {{ $tc("process_area") }}
          <ToolTip :title="hint || $parent.$t('hints.process_area')" />
        </label>
        <template v-if="editable">
          <div
            class="input-group"
            v-if="!process_area_editing"
            style="width:100%"
          >
            <div class="input-group-addon hidden-xs" v-if="parentProcessAreaId">
              {{ $tc("process_area") }}
            </div>
            <div class="form-control" v-if="lastAreaName">
              <i class="fa fa-refresh fa-spin"></i>
            </div>
            <select
              v-else
              class="form-control"
              data-testid="process-area"
              v-model="process_area_id"
              v-bind:disabled="
                !$can('manage', 'AreaDeProcessoAcesso') ||
                  inherits_parent_process_area ||
                  lastAreaName != '' ||
                  disabled
              "
              style="min-width: 140px"
              :title="(processArea && processArea.description) || ''"
            >
              <option
                v-for="(item, index) in processAreas"
                v-bind:key="index"
                v-bind:value="item.id"
                v-bind:title="item.description"
                >{{ item.name }}</option
              >
            </select>
          </div>
        </template>
        <template v-else>
          <div class="form-control" v-if="lastAreaName">
            <i class="fa fa-refresh fa-spin"></i>
          </div>
          <select
            v-else
            class="form-control"
            data-testid="process-area"
            v-model="process_area_id"
            v-bind:disabled="
              !$can('manage', 'AreaDeProcessoAcesso') ||
                inherits_parent_process_area ||
                lastAreaName != '' ||
                disabled
            "
            style="min-width: 140px"
            :title="(processArea && processArea.description) || ''"
          >
            <option
              v-for="(item, index) in processAreas"
              v-bind:key="index"
              v-bind:value="item.id"
              v-bind:title="item.description"
              >{{ item.name }}</option
            >
          </select>
        </template>
      </div>
      <div
        :class="{ 'pull-right': inline }"
        :style="
          !inline
            ? 'flex: 0 0 80px; align-self: flex-end; white-space: nowrap'
            : ''
        "
        v-if="editable && !process_area_editing"
      >
        <span
          class="btn btn-default btn-gap"
          v-bind:disabled="
            inherits_parent_process_area || disabled || process_area_id == 0
          "
          v-bind:title="$t('edit')"
          v-on:click.prevent.stop="edit()"
          v-if="$can('manage', 'AreaDeProcessoCadastro')"
        >
          <i class="fa fa-pencil"></i>
        </span>
        <span
          class="btn btn-primary"
          v-bind:disabled="inherits_parent_process_area || disabled"
          v-bind:title="$t('new_process_area')"
          v-on:click.prevent.stop="add()"
          v-if="$can('manage', 'AreaDeProcessoCadastro')"
        >
          <i class="fa fa-plus"></i>
        </span>
      </div>
    </div>

    <ProcessAreaInlineForm
      v-if="process_area_editing"
      v-bind:processArea="process_area_editing"
      v-on:hide="onProcessAreaFormClosed"
    ></ProcessAreaInlineForm>
  </div>
</template>

<script>
import ToolTip from "@/components/tooltip.vue";
import ProcessAreaInlineForm from "@/components/process-area-inline-form.vue";
export default {
  name: "ProcessAreaSelection",
  components: {
    ToolTip,
    ProcessAreaInlineForm
  },
  props: {
    value: {
      type: Object,
      required: false,
      default: null
    },
    parentProcessAreaId: {
      type: Number,
      required: false,
      default: 0 // 0 will disable the checkbox option
    },
    label: {
      type: String,
      required: false,
      default: "connector"
    },
    hint: {
      type: String,
      required: false,
      default: ""
    },
    allowNull: {
      type: Boolean,
      required: false,
      default: false
    },
    nullLabel: {
      type: String,
      required: false,
      default: function() {
        return this.$tc("none", 2);
      }
    },
    inline: {
      type: Boolean,
      required: false,
      default: false
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false
    },
    editable: {
      type: Boolean,
      required: false,
      default: true
    }
  },
  data() {
    return {
      inherits_parent_process_area: false,
      process_area_id: 0,
      process_area_editing: null,
      lastAreaName: ""
    };
  },
  watch: {
    inherits_parent_process_area(n) {
      if (n) {
        this.$set(this, "process_area_id", this.parentProcessAreaId);
      }
      this.triggerValueChangeEvent();
    },
    process_area_id(n) {
      this.triggerValueChangeEvent();
    },
    processAreas: function(n, o) {
      this.selectLastProcessArea();
    }
  },
  computed: {
    processAreas() {
      let aditional = [];
      if (
        this.allowNull &&
        this.$store.getters["processArea/processAreas"].length
      )
        aditional.push({ id: 0, name: this.nullLabel });
      return aditional.concat(this.$store.getters["processArea/processAreas"]);
    },
    processArea() {
      let id = this.process_area_id;
      let lst = this.processAreas.filter(function(i) {
        return i.id == id;
      });
      return (lst.length && lst[0]) || null;
    }
  },
  methods: {
    triggerValueChangeEvent() {
      this.$emit("input", {
        inherits_parent_process_area: this.inherits_parent_process_area,
        id: this.process_area_id || null
      });
    },
    fetchProcessAreas() {
      this.$store.dispatch("processArea/fetchProcessAreas");
    },
    add() {
      if (this.inherits_parent_process_area || this.disabled) return;
      this.process_area_editing = {};
    },
    edit() {
      if (
        this.inherits_parent_process_area ||
        this.disabled ||
        this.process_area_id == 0
      )
        return;
      this.process_area_editing = JSON.parse(JSON.stringify(this.processArea));
    },
    onProcessAreaFormClosed(name) {
      this.process_area_editing = null;
      if (!name) {
        let pa = (this.processAreas || []).find(({ is_default }) => is_default);
        this.lastAreaName = pa && pa.name;
        if (this.process_area_id) {
          this.$emit("processAreaRemoved", this.process_area_id);
        }
      } else {
        this.lastAreaName = name;
      }
      //===============
      // reset previous selection
      var query = this.$store.getters["equipment/query"] || {};
      query.query_process_area = query.query_process_area || {};
      query.query_process_area.id = "";
      this.$store.dispatch("equipment/setQuery", query);
      //===============
      this.selectLastProcessArea();
    },
    selectLastProcessArea() {
      if (this.lastAreaName) {
        let pa = (this.processAreas || []).find(
          (i) => i.name == this.lastAreaName
        );
        if (pa) {
          this.process_area_id = pa.id;
        }
      }
      this.lastAreaName = "";
    }
  },
  mounted() {
    this.inherits_parent_process_area = this.value.inherits_parent_process_area;
    this.process_area_id =
      (this.inherits_parent_process_area && this.parentProcessAreaId) ||
      (this.value.id ?? 0);
    if (!(this.processAreas || []).length) {
      this.fetchProcessAreas();
    }
  }
};
</script>

<style scoped>
.btn-gap {
  margin-right: 5px;
}
.inline-flex {
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 36em;
}
.block {
  display: block;
  width: 100%;
}
.inline-flex .form-group {
  padding-right: 5px;
}
.inline-block {
  display: inline-block;
}
</style>

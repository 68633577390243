<template>
  <div ref="me" @click.self.stop.prevent>
    <ul class="list-group">
      <li
        v-for="item in sortedList"
        :key="item.name"
        :draggable="canCopy(item)"
        class="list-group-item clicable"
        :class="{
          active: isCurrent(item.name),
          dragging: draggingPanel && draggingPanel == item.name
        }"
        @click.stop.prevent="select(item)"
        @dragstart="onDragStart($event, item)"
        @dragend="onDragEnd($event)"
        @dragover="jsonDragger.onDragOver($event)"
        :title="tooltip(item)"
      >
        <img
          :src="originalPanel(item).thumbnail"
          style="width: 24px; height: 24px"
        />
        <span>
          {{ originalPanelTitle(item) }}
          <PanelPosition
            :panelName="item.name"
            class="small"
            style="vertical-align: top; color: #999"
          />
        </span>
        <span class="item-buttons">
          <span
            v-if="canCopy(item) && !draggingPanel"
            class="btn btn-xs icon-opaque"
          >
            <i class="fa fa-copy"></i>
          </span>
          <span
            v-if="linkedScreen(item.name)"
            class="btn btn-xs"
            @click.stop.prevent="togglePanelSync(item.name)"
            :title="
              $t(
                isPanelSyncEnabled(item.name) ? 'sync_enabled' : 'sync_disabled'
              )
            "
          >
            <i
              :class="
                isPanelSyncEnabled(item.name) ? 'fa fa-desktop' : 'fa fa-unlink'
              "
            ></i>
          </span>
          <span
            v-if="!draggingPanel"
            class="btn btn-xs"
            @click.stop.prevent="delPanel(item.name)"
          >
            <i class="fa fa-trash"></i>
          </span>
          <span v-if="draggingPanel && draggingPanel == item.name">
            <i class="fa fa-copy"></i>
          </span>
        </span>
      </li>
    </ul>
  </div>
</template>

<script>
import PanelPosition from "@/components/control-sidebar/property-editors/panel-position.vue";
import { panelPosition } from "@/services/dashboard.js";
import messages from "@/i18n/dashboard-panels.js";
export default {
  name: "PanelList",
  components: {
    PanelPosition
  },
  data: () => ({
    draggingPanel: ""
  }),
  computed: {
    template() {
      return this.$store.getters["dashboard/draft"]?.template || null;
    },
    currentDraftPanel() {
      return this.$store.getters["dashboard/currentDraftPanel"] || null;
    },
    panels() {
      return this?.template?.panels || [];
    },
    allPanels() {
      return this.$store.getters["dashboard/allPanels"] || [];
    },
    sortedList() {
      let lst = this.panels.map((panel) => ({
        ix: this.position(panel.name),
        panel: panel
      }));
      return lst
        .sort((a, b) => (a.ix > b.ix ? 1 : a.ix < b.ix ? -1 : 0))
        .map(({ panel }) => panel);
    }
  },
  watch: {
    currentDraftPanel: {
      handler(n) {
        let ix =
          n && n.name
            ? this.panels.findIndex(({ name }) => name == n.name)
            : -1;
        if (ix > -1) {
          this.active = ix;
        }
      },
      deep: true,
      immediate: true
    }
  },
  methods: {
    canCopy(panel) {
      return !this.originalPanel(panel).singleton;
    },
    isCurrent(panelName) {
      return this?.currentDraftPanel?.name == panelName;
    },
    originalPanel(panel) {
      return (this.allPanels || []).find(
        (p) => p.template.template == panel.template
      );
    },
    originalPanelTitle(panel) {
      return this.$t(`${this.originalPanel(panel).title}`);
    },
    panelTitle(panel) {
      let result = this.$utils.evaluate({
        connector: this.$store.getters["dashboard/dashboardEquipment"] || {}
      }, panel?.title) || panel?.title;
      return (this.$t(result));
    },
    tooltip(panel) {
      let o = this.panelTitle(panel);
      let n = this.originalPanelTitle(panel);
      return n != o ? `${n}\n${o}` : n;
    },
    delPanel(panelName) {
      this.$root.$emit("dashboard:editor", {
        action: "delPanel",
        data: { panelName: panelName }
      });
    },
    togglePanelSync(panelName) {
      this.$root.$emit("dashboard:editor", {
        action: "setPanelSync",
        data: {
          panelName: panelName,
          option: !this.isPanelSyncEnabled(panelName)
        }
      });
    },
    isPanelSyncEnabled(panelName) {
      return this.linkedScreen(panelName)?.syncEnabled || false;
    },
    linkedScreen(panelName) {
      return (this?.template?.linkedPanels || {})[panelName];
    },
    select(panel) {
      if (this.isCurrent(panel.name)) {
        this.$root.$emit("dashboard:editor", { action: "editDashboard" });
      } else {
        this.$root.$emit("dashboard:editor", {
          action: "editPanel",
          data: { panelName: panel.name, scrollTo: true, showContentProperties: false }
        });
        if (panel.template == "SynopticPanel") {
          this.$store.dispatch("synoptic/setPropertyEditorEnabled", false);
        }
        this.$nextTick(() => {
          this.$root.$emit("drawer:event", {
            action: "tab:activate",
            details: "components"
          });
        });
      }
    },
    onDragStart($event, panel) {
      if (panel && panel.name && this.canCopy(panel)) {
        this.draggingPanel = panel.name;
        this.jsonDragger.onDragStart($event, "panel", {
          pasting: panel.name
        });
      }
    },
    onDragEnd($event) {
      this.draggingPanel = "";
      this.jsonDragger.onDragEnd($event);
    },
    position(panelName) {
      let p = (panelName &&
        this.template &&
        panelPosition(this.template, panelName)) || { col: 0, row: 0 };
      return `${this.$utils.sprintf("%03d", p.row)},${this.$utils.sprintf("%03d", p.col)}`;
    }
  },
  created() {
    this.jsonDragger = this.$utils.JSONDragger();
  },
  beforeDestroy() {
    delete this.jsonDragger;
  },
  i18n: { messages }
};
</script>

<style scoped>
.clicable:hover {
  cursor: pointer;
  opacity: 0.8;
}

.list-group-item {
  padding: 4px 10px 4px 2px;
}

.list-group-item > span {
  vertical-align: middle;
}

.list-group-item.dragging {
  color: rgb(85, 85, 85);
  outline-color: orange;
  outline-offset: -2px;
  outline-style: solid;
  outline-width: 1px;
  font-weight: 600;
  background-color: lightgoldenrodyellow;
}

.list-group-item > .item-buttons {
  position: absolute;
  z-index: 9999;
  right: 0;
}

.btn-group-xs > .btn,
.btn-xs {
  padding: 1px 3px;
}

.list-group-item > .item-buttons > .btn {
  opacity: 0.8;
  margin-right: 5px;
}

.list-group-item > .item-buttons > .btn:hover {
  opacity: 1;
}
.list-group-item > .item-buttons > .icon-opaque {
  color: transparent;
}
.list-group-item:hover > .item-buttons > .icon-opaque {
  color: rgb(182, 124, 16);
}
</style>

<template>
  <div class="permissions-container">
    <SelectMultipleProcessAreas
      :value="value.view_permission"
      @input="$emit('input', { ...value, view_permission: $event })"
      :label="$t('restrict_view')"
      :tooltipTitle="$t(`titles.${tooltipNoun}_access_by_process_area`)"
    />
    <SelectMultipleProcessAreas
      :value="value.interaction_permission"
      @input="$emit('input', { ...value, interaction_permission: $event })"
      :label="$t('restrict_interaction')"
      :tooltipTitle="$t(`titles.${tooltipNoun}_interaction_by_process_area`)"
    />
  </div>
</template>

<script>
import SelectMultipleProcessAreas from "@/components/select-multiple-process-areas";

export default {
  name: "ProcessAreaPermissions",
  components: {
    SelectMultipleProcessAreas
  },
  props: {
    value: {
      type: Object,
      default: () => ({
        view_permission: [],
        interaction_permission: []
      })
    },
    tooltipNoun: {
      type: String,
      default: "control"
    }
  }
};
</script>

<style scoped>
.permissions-container > div:first-child {
  margin-bottom: 10px;
}
</style>

<template>
  <component :is="tag" :class="defaultWrapperClass ? 'btn-group' : ''">
    <BaseButton
      v-if="$slots.default"
      v-bind="btnAttrs"
      :type="type"
      v-on="$listeners"
      :disabled="disabled"
      :defaultClass="defaultButtonClass"
      :tag="buttonTag"
      data-testid="button"
    >
      <slot></slot>
    </BaseButton>
    <BaseButton
      v-if="caret"
      :type="type"
      :disabled="disabled"
      :defaultClass="defaultCaretClass"
      v-bind="caretAttrs"
      v-on="caretListeners"
      data-testid="caret"
    >
      <span class="caret"></span>
      <span class="sr-only" data-testid="sr-only">{{ toggleSrText }} </span>
    </BaseButton>
    <ul class="dropdown-menu" data-testid="options">
      <slot name="options"></slot>
    </ul>
  </component>
</template>

<script>
import BaseButton from "./base-button";

export default {
  name: "DropdownButton",
  inheritAttrs: false,
  components: { BaseButton },
  props: {
    type: {
      type: String,
      required: false,
      default: "default"
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false
    },
    tag: {
      type: String,
      required: false,
      default: "div"
    },
    buttonTag: {
      type: String,
      required: false,
      default: "button"
    },
    defaultClass: {
      type: [Boolean, Object],
      required: false,
      default: true
    },
    caret: {
      type: Boolean,
      required: false,
      default: true
    },
    toggleSrText: {
      type: String,
      required: false,
      default() {
        return this.$t("toggle_dropdown");
      }
    }
  },
  data() {
    return {
      dropdownToggleAttrs: {
        class: "dropdown-toggle",
        "data-toggle": "dropdown",
        "aria-haspopup": "true",
        "aria-expanded": "false"
      }
    };
  },
  computed: {
    caretAttrs() {
      return {
        ...this.dropdownToggleAttrs,
        ...(!this.$slots.default && this.caret ? this.$attrs : {})
      };
    },
    caretListeners() {
      return !this.$slots.default && this.caret ? this.$listeners : {};
    },
    btnAttrs() {
      return {
        ...(!this.caret ? this.dropdownToggleAttrs : {}),
        ...this.$attrs
      };
    },
    defaultWrapperClass() {
      return this.defaultClass?.wrapper == true || this.defaultClass == true;
    },
    defaultCaretClass() {
      return this.defaultClass?.caret == true || this.defaultClass == true;
    },
    defaultButtonClass() {
      return this.defaultClass?.button == true || this.defaultClass == true;
    }
  }
};
</script>

<style scoped>
.dropdown-menu a {
  cursor: pointer;
}
</style>

<template>
  <div class="checkbox" :title="$attrs.title">
    <label :for="id" :class="labelClass">
      <input
        :id="id"
        type="checkbox"
        v-bind="$attrs"
        v-on="listeners"
        :title="inputTitle"
        :value="value"
        :checked="isChecked"
        :indeterminate.prop="indeterminate"
        @input="update"
        v-if="!right"
      />
      <slot></slot>
      <input
        :id="id"
        type="checkbox"
        v-bind="$attrs"
        v-on="listeners"
        :title="inputTitle"
        :value="value"
        :checked="isChecked"
        :indeterminate.prop="indeterminate"
        @input="update"
        v-if="right"
      />
    </label>
  </div>
</template>

<script>
import omit from "lodash/omit";
import isEqual from "lodash/isEqual";

export default {
  name: "Checkbox",
  inheritAttrs: false,
  model: {
    prop: "checked",
    event: "change"
  },
  props: {
    checked: {
      type: [Boolean, Array],
      default: false
    },
    right: {
      type: Boolean,
      default: false
    },
    id: {
      type: String,
      default: ""
    },
    value: {
      default: "on"
    },
    indeterminate: {
      type: Boolean,
      default: false
    },
    inputTitle: {
      type: String,
      default: ""
    },
    labelClass: {
      type: String,
      default: ""
    }
  },
  computed: {
    listeners() {
      return omit(this.$listeners, "change");
    },
    isChecked() {
      return this.checked instanceof Array
        ? this.checked.some((value) => isEqual(value, this.value))
        : this.checked;
    }
  },
  methods: {
    update(e) {
      let { checked, _value: value } = e.target;
      // if the value is boolean
      if (typeof this.checked == "boolean") {
        this.$emit("change", checked);
      } else {
        // if the value is an array
        if (checked && !this.checked.includes(value)) {
          this.$emit("change", [...this.checked, value]);
        } else if (!checked && this.checked.includes(value)) {
          this.$emit(
            "change",
            this.checked.filter((v) => v != value)
          );
        }
      }
    }
  }
};
</script>

<style></style>

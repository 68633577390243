<template>
  <portal to="modal">
  <Modal
    :open="modalOpen"
    @hide="$emit('hide')"
    :title="$t('titles.screen_export_modal')"
    :footer="false"
    :canClose="!busy"
    backdrop="static"
  >
    <template #content>
      <div class="row">
        <div class="col-md-12 text-center" style="padding: 50px;" v-if="busy">
          <Spin />
        </div>

        <div class="col-md-3" v-if="!isScreenPublished && !busy"></div>
        <div class="col-md-6" v-if="isScreenPublished && !busy">
          <button class="btn btn-success btn-block btn-lg" @click="downloadZIP" :title="$t('hints.download_screen_zip')" :disabled="busy">
            <i class="fa fa-file-archive-o"></i>
            {{ $t("titles.download_screen_zip") }}
          </button>
        </div>

        <div class="col-md-6" v-if="!busy">
          <button class="btn btn-primary btn-block btn-lg" @click="downloadJSON" :title="$t('hints.download_screen_json')" :disabled="busy">
            <i class="fa fa-file-code-o"></i>
            {{ $t("titles.download_screen_json") }}
          </button>
        </div>
      </div>
    </template>
  </Modal>
  </portal>
</template>
<script>
import DashboardPublisher from "@/components/editor/dashboard-publisher.vue";
import Modal from "@/components/widgets/modal.vue";
import Spin from "@/components/spin";
import ScreenService from "@/services/screen.js";
import Auth from "@/services/auth.js";
import MixinAlert from "@/project/mixin-alert.js";

export default {
  name: "ModalExportScreen",
  extends: DashboardPublisher,
  mixins: [MixinAlert],
  components: {
    Modal,
    Spin
  },
  props: {
    screenId: {
      type: Number
    },
    modalOpen: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      busy: false
    };
  },
  watch: {
    screenId: {
      handler(n,o) {
        this.busy = false;
      },
      immediate: true
    }
  },
  computed: {
    screen() {
      return this.$store.getters["dashboard/screen"](this.screenId) || {};
    },
    isScreenPublished() {
      return this.screenId && this.screenId > 0;
    },
    downloadZipURL() {
      return `${this.$root.config.api_url}/screens/${this.screenId}/backup/` 
    }
  },
  methods: {
    async downloadJSON() {
      this.busy = true;
      let content = await this.content();
      if (!content) return;
      content.origin = this?.screen?.path || "";
      this.$utils.download(
        JSON.stringify(content, null, "\t"),
        "application/json",
        this.screenName() + ".json"
      );
      this.$nextTick(() => this.$emit("close"));
    },
    downloadZIP() {
      this.busy = true;

      let url = this.downloadZipURL;
      let auth = new Auth();
      
      // Realiza o download
      fetch(url, auth.requestOptions())
        .then((response) => {
          // Caso obtenha sucesso, retorna o BLOB (conteúdo do arquivo)
          if (response.status === 200) {
            return response.blob();
          // Caso contrário, exibe alerta com o erro
          } else {
            response.text().then((text) => {
              try {
                text = JSON.parse(text).detail;
              } catch { }

              this.alert = {
                title: this.$t("error"),
                text: text,
                type: "error"
              };
              this.showAlert(function() {});
              this.busy = false;
            })
          }
        })
        .then((blob) => {
          // Cria o download local a partir do BLOB (conteúdo do arquivo)
          if (!blob) return;
          const url = window.URL.createObjectURL(blob);
          const a = document.createElement('a');
          a.style.display = 'none';
          a.href = url;
          a.download = `${this.screenName()}.hps`; // HPS / ZIP
          document.body.appendChild(a);
          a.click();
          window.URL.revokeObjectURL(url);
          this.busy = false;
          this.$nextTick(() => this.$emit("close"));
        });
    },
    screenName() {
      let name = this.screen.name;
      if (this.screen.id < 0) {
        name = this.$t("new_screen");
        name = `${name} (${
          -1 * this.screen.id <= 9
            ? "0" + -1 * this.screen.id
            : -1 * this.screen.id
        })`;
      }
      return name;
    }
  },
  mounted() {
    this.service = new ScreenService();
  }
};
</script>

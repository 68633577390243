export default {
  methods: {
    hasPermission(
      rules,
      contract = this.contract ?? this.$store.getters["user/contract"]
    ) {
      let allowed;
      if ((rules || "").includes("|")) {
        allowed = false;
        (rules || "").split("|").forEach((item) => {
          let rule = this.$utils.trim(item);
          if (contract && rule in contract) {
            if (contract[rule] == true) {
              allowed = true;
            }
          } else {
            if (this.$can("manage", rule)) {
              allowed = true;
            }
          }
        });
      } else {
        allowed = true;
        (rules || "").split(",").forEach((item) => {
          let rule = this.$utils.trim(item);
          if (contract && rule in contract) {
            if (contract[rule] == false) {
              allowed = false;
            }
          } else {
            if (!this.$can("manage", rule)) {
              allowed = false;
            }
          }
        });
      }
      return allowed;
    }
  }
};

import Vue from "vue";
import VueResource from "vue-resource";
import AuthService from "@/services/auth";

Vue.use(VueResource);

export default class NotificationService {
  constructor() {
    this.auth = new AuthService();
  }

  async fetch(params) {
    try {
      let response = await Vue.http.get("system_notifications/", {
        params,
        ...this.auth.requestOptions()
      });
      return response.body;
    } catch (e) {
      throw new Error("Failed to fetch notifications");
    }
  }

  async get(id, params) {
    try {
      let response = await Vue.http.get(`system_notifications/${id}/`, {
        params,
        ...this.auth.requestOptions()
      });
      return response.body;
    } catch (e) {
      throw new Error("Failed to get notification by id " + id);
    }
  }

  async setRead(system_notification_ids, params = {}) {
    try {
      const payload = {
        system_notification_ids
      };
      await Vue.http.post("system_notifications/read/", payload, {
        params,
        ...this.auth.requestOptions()
      });
    } catch (e) {
      throw new Error(
        "Failed to udpate read status for notifications of ids " +
          system_notification_ids.join(", ")
      );
    }
  }
}

<template>
  <span :title="`${$t('position')} ${position.row + 1},${position.col + 1}`">
    <span style="font-size: 80%">@</span>
    <span style="vertical-align: middle">
      {{ `${position.row + 1},${position.col + 1}` }}
    </span>
  </span>
</template>

<script>
import { panelPosition } from "@/services/dashboard.js";

export default {
  name: "PanelPosition",
  props: {
    panelName: {
      type: String,
      required: false,
      default: ""
    },
    template: {
      type: Object,
      required: false,
      default: () => null
    }
  },
  computed: {
    position() {
      let entry = { col: 0, row: 0 };
      if (!this.panelName) return entry;
      let tpl = this.template || this.$store.getters["dashboard/editorTemplate"] || null;
      return tpl
        ? panelPosition(tpl, this.panelName) || entry
        : entry;
    }
  }
};
</script>

<style scoped></style>

<template>
  <div class="js-script-editor-main">
    <div style="display: flex;">
      <JSEditor
        class="js-editor"
        :enviroment="JSEnviroment()"
        v-model="iValue"
        @save="$emit('save')"
        @ready="$emit('ready')"
      />
    </div>
  </div>
</template>

<script>
import JSEditor from "./js-editor.js";
import SelectableObjects from "@/assets/dashboard/selectable_objects.json";

const JSEnviroment = {
  // input translation
  iconv(vm, value) {
    if (value === "") return value;

    let vlr = (value || "").trimStart().trimEnd();
    return vlr.trimStart().trimEnd();
  },
  // output translation
  oconv(vm, newValue, oldValue) {
    if (newValue === "") return newValue;
    return newValue.trimStart().trimEnd();
  },
  // this enviroment suported context
  context(vm) {
    return new Promise((resolve) => {
      let ctx = JSON.parse(JSON.stringify(SelectableObjects));
      let sys = JSON.parse(JSON.stringify(vm.$store.getters.systemProperties));
      ctx.data.history = { ...ctx.history, ...(sys?.history || {}) };
      ctx.data.device = ctx.device;
      ctx.data.device.connector = ctx.connector;
      ctx.data.user = sys?.user || {};
      ctx.data.broker = sys?.broker || {};
      resolve(ctx);
    });
  }
};

export default {
  name: "JSScriptMain",
  props: {
    value: {
      type: String,
      required: false,
      default: ""
    }
  },
  components: {
    JSEditor
  },
  data: () => ({}),
  computed: {
    iValue: {
      set(vlr) {
        this.$emit("input", vlr);
      },
      get() {
        return this.value || "";
      }
    }
  },
  methods: {
    JSEnviroment: () => JSEnviroment
  }
};
</script>

<style scoped>
div.js-script-editor-main {
  position: absolute;
  right: 0;
  left: 0;
  bottom: 0;
  top: 0;
  padding: 0;
}

div.js-script-editor-main > div {
  display: flex;
  height: 100%;
  width: 100%;
  overflow: auto;
  min-width: 200px;
}

div.js-script-editor-main > div > div {
  align-self: stretch;
  flex: 1;
}

.js-editor {
  padding-top: 5px;
  padding-left: 5px;
  width: calc(100% - 5px);
}
</style>

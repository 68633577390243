<template>
  <div class="pdf-tool-bar">
    <nav class="navbar">
      <ul class="nav navbar-nav">
        <li class="dropdown notifications-menu">
          <a
            href="#"
            class="dropdown-toggle"
            data-toggle="dropdown"
            aria-expanded="true"
            ref="btn"
          >
            <i
              :class="
                userSettings.orientation == 'p'
                  ? 'fa fa-file-o icon-portrait'
                  : 'fa fa-file-o icon-landscape'
              "
            ></i>
            <span class="label label-default">{{
              userSettings.format.toUpperCase()
            }}</span>
          </a>
          <ul class="dropdown-menu">
            <li class="header text-bold">{{ $t("orientation") }}:</li>
            <li>
              <ul class="menu">
                <li
                  class="pull-left"
                  :class="userSettings.orientation == 'p' ? 'active' : ''"
                >
                  <a
                    href="javascript:void(0)"
                    @click.prevent="userSettings.orientation = 'p'"
                  >
                    <span>
                      <i class="fa fa-file-o icon-portrait" />
                      {{ $t("portrait") }}
                    </span>
                  </a>
                </li>
                <li
                  class="pull-right"
                  :class="userSettings.orientation == 'l' ? 'active' : ''"
                >
                  <a
                    href="javascript:void(0)"
                    @click.prevent="userSettings.orientation = 'l'"
                  >
                    <span>
                      <i class="fa fa-file-o icon-landscape" />
                      {{ $t("landscape") }}
                    </span>
                  </a>
                </li>
              </ul>
            </li>
            <li class="header text-bold" style="clear: both">
              {{ $t("size") }}: {{ userSettings.format.toUpperCase() }}
            </li>
            <li>
              <ul class="menu" :class="{ showAll: showAll }">
                <li
                  style="clear: both"
                  v-for="item in pageOptions.options"
                  :key="item.name"
                  :class="item.name == pageOptions.active ? 'active' : ''"
                  @click.stop.prevent="
                    userSettings.format = item.name;
                    showAll = false;
                  "
                >
                  <a href="javascript:void(0)">
                    <i class="fa fa-file-o text-gray"></i>
                    <span>{{ item.name.toUpperCase() }}</span>
                    <span class="pull-right small">
                      {{ item.width }} x {{ item.height }}
                      <em style="color: #8d8d8d">mm</em>
                    </span>
                  </a>
                </li>
              </ul>
            </li>
            <li class="footer">
              <a
                href="javascript:void(0)"
                @click.stop.prevent="showAll = !showAll"
              >
                <i
                  :class="showAll ? 'fa fa-chevron-up' : 'fa fa-chevron-down'"
                ></i>
              </a>
            </li>
          </ul>
        </li>
      </ul>
    </nav>
  </div>
</template>

<script>
import { isEqual } from "lodash";
const pageFormats = {
  a0: [2383.94, 3370.39],
  a1: [1683.78, 2383.94],
  a2: [1190.55, 1683.78],
  a3: [841.89, 1190.55],
  a4: [595.28, 841.89],
  a5: [419.53, 595.28],
  a6: [297.64, 419.53],
  a7: [209.76, 297.64],
  a8: [147.4, 209.76],
  a9: [104.88, 147.4],
  a10: [73.7, 104.88],
  b0: [2834.65, 4008.19],
  b1: [2004.09, 2834.65],
  b2: [1417.32, 2004.09],
  b3: [1000.63, 1417.32],
  b4: [708.66, 1000.63],
  b5: [498.9, 708.66],
  b6: [354.33, 498.9],
  b7: [249.45, 354.33],
  b8: [175.75, 249.45],
  b9: [124.72, 175.75],
  b10: [87.87, 124.72],
  c0: [2599.37, 3676.54],
  c1: [1836.85, 2599.37],
  c2: [1298.27, 1836.85],
  c3: [918.43, 1298.27],
  c4: [649.13, 918.43],
  c5: [459.21, 649.13],
  c6: [323.15, 459.21],
  c7: [229.61, 323.15],
  c8: [161.57, 229.61],
  c9: [113.39, 161.57],
  c10: [79.37, 113.39],
  dl: [311.81, 623.62],
  letter: [612, 792],
  // "government-letter": [576, 756],
  legal: [612, 1008]
  // "junior-legal": [576, 360]
  // ledger: [1224, 792],
  // tabloid: [792, 1224],
  // "credit-card": [153, 243]
};
export { pageFormats };
export default {
  name: "PageSettingsToolbar",
  props: {
    value: {
      type: Object,
      required: false,
      default: () => null
    }
  },
  data() {
    return {
      showAll: false,
      userSettings: {
        orientation: "p",
        format: "a4"
      }
    };
  },
  computed: {
    pageOptions() {
      let minimal = this.showAll
        ? null
        : ["a0", "a1", "a3", "a4", "letter", "legal"];
      return {
        active: this.userSettings.format,
        options: Object.keys(pageFormats)
          .filter((k) => (minimal ? minimal.indexOf(k) >= 0 : true))
          .sort()
          .map((k) => ({
            name: k,
            width: (pageFormats[k][0] / 2.83).toFixed(0),
            height: (pageFormats[k][1] / 2.83).toFixed(0)
          }))
      };
    }
  },
  watch: {
    userSettings: {
      handler(n) {
        if (!n || isEqual(n, this.value)) return;
        this.$emit("input", JSON.parse(JSON.stringify(n)));
      },
      deep: true
    },
    value: {
      handler(n) {
        if (!n || isEqual(n, this.userSettings)) return;
        this.userSettings = JSON.parse(JSON.stringify(n));
      },
      deep: true,
      immediate: true
    }
  },
  methods: {
    toggle($event) {
      if ($event) {
        $event.stopPropagation();
        $event.preventDefault();
        if (this.$refs.btn) {
          $(this.$refs.btn).click();
        }
      }
    }
  }
};
</script>

<style scoped>
.pdf-tool-bar {
}

.pdf-tool-bar > .navbar > .nav > li > a > .label {
  position: absolute;
  top: 9px;
  right: 7px;
  text-align: center;
  font-size: 9px;
  padding: 2px 3px;
  line-height: 0.9;
}

.pdf-tool-bar
  .navbar-nav
  > .notifications-menu
  > .dropdown-menu
  > li
  .menu
  > li
  > a {
  color: inherit;
}

.pdf-tool-bar .navbar-nav > .notifications-menu > .dropdown-menu {
  width: 225px;
  padding: 0;
  margin: 0;
  top: 100%;
  left: -180px;
  border: 1px solid lightgray;
  border-radius: 4px;
}

.pdf-tool-bar ul.menu > li.active {
  background-color: whitesmoke;
  color: #337ab7;
  font-weight: 600;
}

.pdf-tool-bar .navbar-nav > .notifications-menu > .dropdown-menu > li .menu {
  max-height: 200px;
  margin: 0;
  padding: 0;
  list-style: none;
  overflow-x: hidden;
  transition: max-height 0.5s cubic-bezier(0.075, 0.82, 0.165, 1);
}
.pdf-tool-bar
  .navbar-nav
  > .notifications-menu
  > .dropdown-menu
  > li
  .menu::-webkit-scrollbar {
  width: 0;
}
.pdf-tool-bar
  .navbar-nav
  > .notifications-menu
  > .dropdown-menu
  > li
  .menu.showAll {
  max-height: 40vh;
  overflow-x: hidden;
}
.icon-portrait {
  margin-right: 2px;
}
.icon-landscape {
  transform: rotate(270deg);
  vertical-align: top;
  margin-top: 2px;
  margin-right: 2px;
}

@media (min-width: 768px) {
  .navbar-nav > li > a {
    padding: 10px 15px;
  }
}
.navbar-nav > li > a {
  padding: 10px 15px;
  line-height: 20px;
}
</style>

<script>
import DashboardService from "@/services/dashboard";

export default {
  name: "DashboardPublisher",
  computed: {
    screenRefMap() {
      let ref_map = this.$store.getters["dashboard/screenRefMap"](
        this.screen.id
      );
      ref_map = ref_map?.conn1 ? ref_map : null;
      return ref_map;
    },
    contractId() {
      return this.$store.getters["user/contract"].id;
    },
    dataList() {
      return this.$store.getters["dashboard/dataList"];
    },
    connectorList() {
      return this.$store.getters["dashboard/connectorList"];
    }
  },
  methods: {
    flatPanel(panel) {
      if (panel) {
        let n = JSON.parse(JSON.stringify(panel));
        delete n.id;
        for (let i in n?.options?.controls || []) {
          delete n?.options?.controls[i].id;
        }
        return n;
      }
      return null;
    },
    parseConnectorModelsDataIds(tpl) {
      for (var prop in tpl) {
        var node = tpl[prop];
        if (typeof node == "object") {
          this.parseConnectorModelsDataIds(node);
        } else if (prop == "data_id" && node) {
          // skip numbers
          if (isNaN(Number(node))) {
            var re = node.match(/connector_(\d+)_.+/);
            if (re && re.length > 0 && re[1]) {
              let conn = this.connectorList.find(({id}) => id == re[1]);
              if (conn && conn.base_model) {
                tpl[prop] = node.replace(`${re[1]}_`, "");
              }
            }
          }
        }
      }
    },
    replaceDataId(tpl) {
      let data = {
        data_id: {}
      };
      if (this.dataList?.length) {
        this.dataList.forEach((item) => {
          if (item?.device?.data_collector_device_id) {
            data.data_id[
              item.id
            ] = `${item.device.reference_id}/${item.reference_id}`;
          } else if (item?.device?.connector?.base_model) {
            data.data_id[item.id] = item.reference_id;
          } else {
            data.data_id[item.id] = item.id;
          }
        });
      }
      let srv = new DashboardService();
      return srv.renderDashboardConfiguration(tpl, data);
    },
    getDataIds(template, recursive = false) {
      let dataIds = {};
      for (let prop in template) {
        if (typeof template[prop] == "object") {
          dataIds = {
            ...dataIds,
            ...this.getDataIds(template[prop], true)
          };
        } else if (prop == "data_id" && typeof template[prop] == "number") {
          dataIds[template[prop]] = true;
        }
      }
      if (!recursive) {
        dataIds = Object.keys(dataIds).map((id) => parseInt(id));
      }
      return dataIds;
    },
    async fetchDataList(dataIdList) {
      return this.$store.dispatch("dashboard/fetchResources", {
        resource: "data",
        list: dataIdList
      });
    },
    async content() {
      let template = this.$store.getters["dashboard/editorTemplate"];
      if (!template) return null;
      let dataIdList = this.getDataIds(template);
      await this.fetchDataList(dataIdList);
      delete template.ref_map;
      let dashboard = this.replaceDataId(template);
      this.parseConnectorModelsDataIds(template);
      dashboard.webapp_version = this.$store.getters["appVersion"] || "";
      if (this.screen.created_at && !dashboard.created_at) {
        dashboard.created_at = new Date(this.screen.created_at).getTime();
      }
      dashboard.published_at = new Date().getTime();
      dashboard.sourceDataList = (dataIdList || []).map((dataId) => ({
        id: dataId,
        name:
          this.dataList.find(({id}) => parseInt(id) === parseInt(dataId))
            ?.name ?? "unknown"
      }));
      return dashboard;
    },
    // remove only the draft content
    removeDraft() {
      this.$store.dispatch("dashboard/removeDraft", this.screen.id);
      // this.$store.dispatch("dashboard/removeEtag", this.screen.id);
    },
    // remove all draft content and screen
    removeScreen() {
      this.removeDraft();
      this.$store.dispatch("dashboard/remove", this.screenId);
    }
  }
};
</script>

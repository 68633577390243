<template>
  <div>
    <InputSearch v-model="queryString" />
    <div style="clear: both">
      <div
        v-for="(item, ix) in list"
        class="item clicable"
        :class="view"
        :key="ix"
        @dblclick.stop.prevent="onAddItem(item)"
        @dragstart="jsonDragger.onDragStart($event, 'layout', item.template)"
        @dragend="jsonDragger.onDragEnd($event)"
        @dragover="jsonDragger.onDragOver($event)"
        :title="`${$t(item.title)}\n${$t('double_click_or_drag_to_insert', {
          item: 'dashboard'
        })}`"
      >
        <div>
          <img :src="item.thumbnail" />
          <span class="small" draggable="true" v-if="view == 'list'">
            {{ $t(item.title) }}
          </span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Layouts from "@/assets/dashboard/layouts.json";
import InputSearch from "@/components/widgets/input-search.vue";
export default {
  name: "LayoutGallery",
  props: {
    view: {
      type: String,
      required: false,
      default: "inline"
    },
    order: {
      type: Boolean,
      required: false,
      default: undefined
    }
  },
  components: { InputSearch },
  data: () => ({
    queryString: ""
  }),
  computed: {
    template() {
      return this.$store.getters["dashboard/draft"]?.template || null;
    },
    filteredList() {
      return Layouts.map((i) => {
        i.lt = this.$t(i.title);
        return i;
      }).filter((i) => {
        var ret = true;
        if (this.queryString) {
          ret = this.$utils.queryStrAtr(this.queryString, {
            a: i.lt
          });
        }
        return ret;
      });
    },
    list() {
      if (this.order === undefined) {
        return this.filteredList;
      } else {
        let o = [
          [1, -1], // asc
          [-1, 0] // dsc
        ][this.order ? 1 : 0];
        return [...this.filteredList].sort((a, b) =>
          a.lt > b.lt ? o[0] : a.lt < b.lt ? o[1] : 0
        );
      }
    }
  },
  methods: {
    onAddItem(item) {
      this.$root.$emit("dashboard:editor", {
        action: "addLayout",
        data: {
          row: -1,
          layout: item.template
        }
      });
    }
  },
  beforeCreate() {
    this.jsonDragger = this.$utils.JSONDragger({
      onDragging: (o) => this.$store.dispatch("dashboard/dragging", o ? "layout" : "")
    });
  }
};
</script>

<style scoped>
.clicable:hover {
  cursor: pointer;
  opacity: 0.8;
}

div.item {
  font-weight: 600;
  color: #555;
}

div.inline {
  display: inline-block;
  margin: 4px 2px;
  max-width: 50px;
  max-height: 70px;
  border-radius: 5px;
  text-align: center;
  vertical-align: top;
  position: relative;
  overflow: hidden;
  line-height: 1em;
}

div.inline > div > img {
  width: 100%;
  display: inline-block;
  vertical-align: top;
  margin: 5px 0;
}

div.list {
}

div.list > div > img {
  display: inline-block;
  width: 28px;
  height: 24px;
  margin-right: 4px;
  margin-left: -4px;
}

div.list > div > span {
  vertical-align: middle;
  margin-left: 4px;
}
</style>

// Encapsulates handling of portal_data default value
<template>
  <div>
    <slot :portal_data="portalData"></slot>
  </div>
</template>

<script>
import isEqual from "lodash/isEqual";

export default {
  name: "PortalData",
  props: {
    value: {
      type: Object,
      required: false,
      default: () => ({})
    },
    default: {
      type: Object,
      required: false,
      default: () => ({})
    }
  },
  data() {
    return {
      portalData: {},
      localUpdate: false
    };
  },
  watch: {
    portalData: {
      deep: true,
      handler(portalData) {
        if (!this.localUpdate && !isEqual(portalData, this.value)) {
          this.$emit("input", JSON.parse(JSON.stringify(portalData)));
        }
        this.localUpdate = false;
      }
    },
    value: {
      deep: true,
      handler(value) {
        if (!isEqual(value, this.portalData)) {
          this.setLocalPortalData();
        }
      }
    }
  },
  methods: {
    setLocalPortalData() {
      let portalData = JSON.parse(JSON.stringify(this.default));
      if (this.value)
        portalData = Object.assign(
          portalData,
          JSON.parse(JSON.stringify(this.value))
        );
      this.localUpdate = true;
      this.portalData = portalData;
    }
  },
  created() {
    this.setLocalPortalData();
  }
};
</script>

<style></style>

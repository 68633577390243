<template>
  <div
    v-if="$scopedSlots.addonBefore || $scopedSlots.addonAfter"
    class="form-group form-group-sm input-search"
  >
    <label for="queryString" v-if="label">{{ $t(label) }}</label>
    <div class="input-group">
      <slot name="addonBefore" v-if="$scopedSlots.addonBefore">
        <div
          class="input-group-addon btn"
          @click.stop.prevent="$emit('addonClick', 0)"
        >
          <i class="fa fa-chevron-down"></i>
        </div>
      </slot>
      <div class="form-control">
        <input
          type="text"
          class="form-control"
          :placeholder="$t('search')"
          v-model="queryString"
          ref="query_string"
        />
        <div class="input-reset">
          <span
            class="fa fa-close clicable"
            @click="reset"
            v-if="queryString"
          ></span>
          <span class="fa fa-search text-gray" v-else></span>
        </div>
      </div>
      <slot name="addonAfter" v-if="$scopedSlots.addonAfter">
        <div
          class="input-group-addon btn"
          @click.stop.prevent="$emit('addonClick', 1)"
        >
          <i class="fa fa-chevron-down"></i>
        </div>
      </slot>
    </div>
  </div>
  <div v-else class="form-group form-group-sm input-search">
    <label for="queryString" v-if="label">{{ $t(label) }}</label>
    <div class="form-control">
      <input
        type="text"
        class="form-control"
        :placeholder="$t('search')"
        v-model="queryString"
        ref="query_string"
      />
      <div class="input-reset">
        <span
          class="fa fa-close clicable"
          @click="reset"
          v-if="queryString"
        ></span>
        <span class="fa fa-search text-gray" v-else></span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "InputSearch",
  props: {
    value: { type: String, default: "", required: false },
    label: { type: String, default: "", required: false }
  },
  computed: {
    queryString: {
      set(value) {
        if (value != this.value) {
          this.$emit("input", value);
        }
      },
      get() {
        return this.value;
      }
    }
  },
  methods: {
    reset() {
      this.queryString = "";
      this.$nextTick(() => {
        if (this.$refs.query_string) {
          this.$refs.query_string.focus();
        }
      });
    }
  }
};
</script>

<style scoped>
div.form-control {
  padding: 0;
  margin: 0;
  border: 0;
}
.input-search {
  position: relative;
  margin-bottom: 0;
  margin-top: -5px;
}

.input-search input {
  background-color: transparent;
  margin-right: 22px;
}

.input-search .input-reset {
  position: absolute;
  width: 20px;
  right: 0px;
  top: 5px;
  z-index: 4;
}
</style>

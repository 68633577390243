<template>
  <div class="me">
    <span
      class="clicable"
      v-for="item in filteredList"
      :key="item.name"
      :title="$t(item.title)"
      :class="{ 'text-primary': view == item.name }"
      @click.stop.prevent="onClick(item.name)"
    >
      <i :class="item.icon"></i>
    </span>
  </div>
</template>

<script>
import { editorSettings } from "@/services/dashboard";
export default {
  name: "ListModePicker",
  props: {
    value: {
      type: String,
      required: false,
      default: "inline"
    },
    enabled: {
      type: Array,
      required: false,
      default: () => (['list', 'inline', 'tree'])
    },
    settingsKey: {
      type: String,
      required: false,
      default: ""
    }
  },
  data: () => ({
    view: undefined, // inline|list,
    list: [
      {
        name: 'tree',
        icon: 'fa fa-indent',
        title: 'grouped'
      },
      {
        name: 'list',
        icon: 'fa fa-list',
        title: 'list'
      },
      {
        name: 'inline',
        icon: 'fa fa-th',
        title: 'in_line'
      }
    ]
  }),
  computed: {
    filteredList() {
      return this.list.filter(({ name }) => (this.enabled || ['list', 'inline']).some((i) => i == name));
    }
  },
  watch: {
    view(n) {
      if (n != this.value) {
        this.$emit("input", n);
        if (this.settingsKey) {
          let entry = editorSettings();
          entry[this.$options.name] = entry[this.$options.name] || {};
          entry[this.$options.name][this.settingsKey] = n;
          editorSettings(entry);
        }
      }
    },
    value(n) {
      if (n != this.view) {
        this.view = n;
      }
    }
  },
  methods: {
    onClick(view) {
      if (view == this.view) {
        this.$emit("newOrder");
      } else {
        this.view = view;
      }
    }
  },
  created() {
    if (this.settingsKey) {
      let entry = editorSettings();
      let prv =
        (entry[this.$options.name] || {})[this.settingsKey] || this.value;
      this.view = prv;
    } else {
      this.view = this.value;
    }
  }
};
</script>

<style scoped>
.clicable:hover {
  cursor: pointer;
  opacity: 0.8;
}

div.me > span {
  margin: 2px 0 2px 6px;
}

div.me::after {
  clear: both;
}
</style>
